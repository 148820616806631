
// @ts-nocheck


export const localeCodes =  [
  "fi",
  "en",
  "cs",
  "et"
]

export const localeLoaders = {
  fi: [
    {
      key: "locale_fi_46json_f39c2013",
      load: () => import("#nuxt-i18n/f39c2013" /* webpackChunkName: "locale_fi_46json_f39c2013" */),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_46json_66d65fac",
      load: () => import("#nuxt-i18n/66d65fac" /* webpackChunkName: "locale_en_46json_66d65fac" */),
      cache: true
    }
  ],
  cs: [
    {
      key: "locale_cs_46json_abc6aa2d",
      load: () => import("#nuxt-i18n/abc6aa2d" /* webpackChunkName: "locale_cs_46json_abc6aa2d" */),
      cache: true
    }
  ],
  et: [
    {
      key: "locale_et_46json_ab715e61",
      load: () => import("#nuxt-i18n/ab715e61" /* webpackChunkName: "locale_et_46json_ab715e61" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/bf8d8440" /* webpackChunkName: "config_i18n_46config_46ts_bf8d8440" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "fi",
      titleKey: "language.finnish",
      language: "fi",
      domains: [
        "members.ruokaboksi.fi"
      ],
      defaultForDomains: [
        "members.ruokaboksi.fi"
      ],
      domainDefault: true,
      gtmContainerId: "GTM-PD9X9G6",
      tracklutionContainerDomain: "https://lstracker.ruokaboksi.fi",
      tracklutionContainerId: "LS-31563842-5",
      brand: "Ruokaboksi",
      loyaltyProgramName: "Kokkiklubi",
      files: [
        "/app/clients/members/i18n/locales/fi.json"
      ]
    },
    {
      code: "en",
      language: "en",
      titleKey: "language.english",
      domains: [
        "members.yummybox.cz",
        "members.yummybox.ee"
      ],
      gtmContainerId: "GTM-T5BZ4VK3",
      tracklutionContainerDomain: "https://tralut.yummybox.cz",
      tracklutionContainerId: "LS-34494794-8",
      brand: "Yummy",
      loyaltyProgramName: null,
      files: [
        "/app/clients/members/i18n/locales/en.json"
      ]
    },
    {
      code: "cs",
      language: "cs",
      titleKey: "language.czech",
      domains: [
        "members.yummybox.cz"
      ],
      defaultForDomains: [
        "members.yummybox.cz"
      ],
      domainDefault: true,
      gtmContainerId: "GTM-T5BZ4VK3",
      tracklutionContainerDomain: "https://tralut.yummybox.cz",
      tracklutionContainerId: "LS-34494794-8",
      brand: "Yummy",
      loyaltyProgramName: null,
      files: [
        "/app/clients/members/i18n/locales/cs.json"
      ]
    },
    {
      code: "et",
      language: "et",
      titleKey: "language.estonian",
      domains: [
        "members.yummybox.ee"
      ],
      defaultForDomains: [
        "members.yummybox.ee"
      ],
      domainDefault: true,
      gtmContainerId: "GTM-MKM485GB\t",
      tracklutionContainerDomain: "https://tralut.cleankitchen.ee/",
      tracklutionContainerId: "LS-82899778-4",
      brand: "Yummy",
      loyaltyProgramName: null,
      files: [
        "/app/clients/members/i18n/locales/et.json"
      ]
    }
  ],
  defaultLocale: "fi",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "fi",
    titleKey: "language.finnish",
    language: "fi",
    domains: [
      "members.ruokaboksi.fi"
    ],
    defaultForDomains: [
      "members.ruokaboksi.fi"
    ],
    domainDefault: true,
    gtmContainerId: "GTM-PD9X9G6",
    tracklutionContainerDomain: "https://lstracker.ruokaboksi.fi",
    tracklutionContainerId: "LS-31563842-5",
    brand: "Ruokaboksi",
    loyaltyProgramName: "Kokkiklubi",
    files: [
      {
        path: "/app/clients/members/i18n/locales/fi.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    language: "en",
    titleKey: "language.english",
    domains: [
      "members.yummybox.cz",
      "members.yummybox.ee"
    ],
    gtmContainerId: "GTM-T5BZ4VK3",
    tracklutionContainerDomain: "https://tralut.yummybox.cz",
    tracklutionContainerId: "LS-34494794-8",
    brand: "Yummy",
    loyaltyProgramName: null,
    files: [
      {
        path: "/app/clients/members/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "cs",
    language: "cs",
    titleKey: "language.czech",
    domains: [
      "members.yummybox.cz"
    ],
    defaultForDomains: [
      "members.yummybox.cz"
    ],
    domainDefault: true,
    gtmContainerId: "GTM-T5BZ4VK3",
    tracklutionContainerDomain: "https://tralut.yummybox.cz",
    tracklutionContainerId: "LS-34494794-8",
    brand: "Yummy",
    loyaltyProgramName: null,
    files: [
      {
        path: "/app/clients/members/i18n/locales/cs.json",
        cache: undefined
      }
    ]
  },
  {
    code: "et",
    language: "et",
    titleKey: "language.estonian",
    domains: [
      "members.yummybox.ee"
    ],
    defaultForDomains: [
      "members.yummybox.ee"
    ],
    domainDefault: true,
    gtmContainerId: "GTM-MKM485GB\t",
    tracklutionContainerDomain: "https://tralut.cleankitchen.ee/",
    tracklutionContainerId: "LS-82899778-4",
    brand: "Yummy",
    loyaltyProgramName: null,
    files: [
      {
        path: "/app/clients/members/i18n/locales/et.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/